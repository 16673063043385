import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/1.HomePage/HomePage';
import ProfileForm from './components/2.ProfileForm/ProfileForm';
import ProblemSolutionFit from './components/3.ProblemSolutionFit/ProblemSolutionFit';
import ServiceForm from './components/4.ServiceForm/ServiceForm';
import ContactForm from './components/5.ContactForm/ContactForm';
import PortFolio from './components/6.PortFolio/PortFolio';
import Pricing from './components/7.Pricing/Pricing';
import About from './components/8.About/About';
import Header from './components/Header';

const isMobileDevice = () => {
  return /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

function App() {
  return (
    <>
      <head>
        <meta name="robots" content="noindex, nofollow" />
      </head>
      <Router>
        <Header />
        {isMobileDevice() ? (
          <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <h1>Accès non autorisé</h1>
            <p>Ce site n'est pas encore disponible sur les appareils mobiles. Veuillez utiliser un ordinateur de bureau pour accéder au site.</p>
          </div>
        ) : (
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/profile-form" element={<ProfileForm />} />
            <Route path="/problem-solution-fit" element={<ProblemSolutionFit />} />
            <Route path="/service-form" element={<ServiceForm />} />
            <Route path="/contact-form" element={<ContactForm />} />
            <Route path="/portfolio" element={<PortFolio />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/about" element={<About />} />
          </Routes>
        )}
      </Router>
    </>
  );
}

export default App;
