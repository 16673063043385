import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import './ContactForm.css';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    entreprise: '',
    contact: '',
    projet: '',
  });

  const [message, setMessage] = useState('');

  useEffect(() => {
    emailjs.init('ce7s5Xk9cSbyW1ZZj'); // Initialisez EmailJS avec votre User ID
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const templateParams = {
      entreprise: formData.entreprise,
      contact: formData.contact,
      projet: formData.projet,
    };
    console.log('Sending the following data to EmailJS:', templateParams);
    try {
      const response = await emailjs.send(
        'service_wjj7572', // Votre Service ID
        'template_6ir46u8', // Votre Template ID
        templateParams
      );
      console.log('EmailJS Response:', response);
      setMessage('Votre message a été envoyé avec succès.');
    } catch (error) {
      console.error('EmailJS Error:', error);
      setMessage('Erreur lors de l\'envoi du message.');
    }
  };

  return (
    <div className="contact-form">
      <h2>Contactez-nous 😊</h2>
      <div className="icons">📞 / ✉️</div>
      <div className="form-container">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="entreprise">Entreprise<span className="required">*</span></label>
            <input
              type="text"
              id="entreprise"
              name="entreprise"
              value={formData.entreprise}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="contact">Contact<span className="required">*</span> ( 📞 ou ✉️ )</label>
            <input
              type="text"
              id="contact"
              name="contact"
              value={formData.contact}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="projet">Projet <span className="optional">(optionnel)</span></label>
            <textarea
              id="projet"
              name="projet"
              value={formData.projet}
              onChange={handleChange}
            />
          </div>
          <button type="submit">Envoyer</button>
        </form>
        {message && <p>{message}</p>}
      </div>
    </div>
  );
};

export default ContactForm;
