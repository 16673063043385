// src/components/Header.js
import React from 'react';
import { Link } from 'react-router-dom'; // Assure-toi d'avoir installé react-router-dom
import './Header.css';

function Header() {
  return (
    <header className="main-header">
      <nav>
        <ul className="nav-links">
          <li><Link to="/">Accueil</Link></li>
          <li><Link to="/service-form">Services</Link></li>
          <li><Link to="/contact-form">Contact</Link></li>
          <li><Link to="/about">À propos</Link></li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
