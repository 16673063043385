import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ProblemSolutionFit.css';


function ProblemSolutionFit({ profile }) {
    const profileTexts = {
        "Un restaurant": {
            title: "Pourquoi un restaurant doit-il être présent en ligne ?",
            content: `
                Aujourd'hui, la présence en ligne est cruciale pour tout restaurant cherchant à se démarquer et à attirer plus de clients.
                Voici pourquoi :
            `,
            points: [
                "Visibilité accrue : Plus de 85% des consommateurs utilisent Internet pour trouver des restaurants locaux. Être présent en ligne vous permet d'être visible là où vos clients potentiels vous cherchent.",
                "Interaction avec les clients : Les avis en ligne et les réseaux sociaux vous offrent une plateforme pour interagir avec vos clients, répondre à leurs commentaires et améliorer leur expérience.",
                "Réservations en ligne : Permettre aux clients de réserver une table en ligne augmente vos réservations et réduit les no-shows.",
                "Marketing digital : Une présence en ligne vous permet de promouvoir des offres spéciales, des événements et des nouveaux plats à un public plus large.",
                "Confiance et crédibilité : Un site web bien conçu et des profils actifs sur les réseaux sociaux renforcent la confiance des clients envers votre restaurant."
            ],
            cta: "Je serais intéressé par..."
        },
        "Un café": {
            title: "Pourquoi un café doit-il être présent en ligne ?",
            content: `
                La présence en ligne est essentielle pour les cafés cherchant à attirer et fidéliser une clientèle diversifiée.
                Voici pourquoi :
            `,
            points: [
                "Visibilité locale : Beaucoup de clients potentiels recherchent des cafés en ligne avant de s'y rendre. Une bonne visibilité en ligne attire plus de clients.",
                "Engagement client : Les réseaux sociaux permettent d'interagir avec les clients, de partager des événements et des offres spéciales, et de créer une communauté fidèle.",
                "Commandes en ligne : Offrir des options de commande en ligne augmente les ventes, surtout en période de forte demande.",
                "Marketing ciblé : Une présence en ligne permet de cibler des publicités et des promotions spécifiques à votre audience idéale.",
                "Amélioration de la réputation : Les avis et témoignages en ligne renforcent la crédibilité et attirent de nouveaux clients."
            ],
            cta: "Je serais intéressé par..."
        },
        "Un hôtel": {
            title: "Pourquoi un hôtel doit-il être présent en ligne ?",
            content: `
                La présence en ligne est indispensable pour tout hôtel souhaitant maximiser ses réservations et améliorer son image.
                Voici pourquoi :
            `,
            points: [
                "Réservations directes : Une présence en ligne bien optimisée permet d'augmenter les réservations directes sans passer par des tiers.",
                "Visibilité globale : Les clients du monde entier peuvent découvrir votre hôtel et réserver en ligne.",
                "Marketing personnalisé : Ciblez des promotions spécifiques pour attirer des segments de clientèle particuliers, comme les voyageurs d'affaires ou les familles.",
                "Gestion des avis : Répondre aux avis en ligne améliore la satisfaction client et montre que vous vous souciez de leur expérience.",
                "Fidélisation : Les programmes de fidélité en ligne encouragent les clients à revenir et à recommander votre hôtel."
            ],
            cta: "Je serais intéressé par..."
        },
        "Une pharmacie": {
            title: "Pourquoi une pharmacie doit-elle être présente en ligne ?",
            content: `
                La présence en ligne est devenue une nécessité pour les pharmacies souhaitant élargir leur portée et offrir un meilleur service.
                Voici pourquoi :
            `,
            points: [
                "Accès à l'information : Offrir des conseils et des informations sur les produits en ligne aide les clients à faire des choix éclairés.",
                "Commandes en ligne : Facilitez la commande de médicaments et produits de santé, augmentant ainsi les ventes et la satisfaction client.",
                "Service clientèle : Répondez aux questions et aux préoccupations des clients via des chats en ligne ou des forums de discussion.",
                "Promotion de la santé : Utilisez les plateformes en ligne pour promouvoir des campagnes de santé et des programmes de bien-être.",
                "Renforcement de la fidélité : Les programmes de fidélité en ligne et les rappels de renouvellement de prescriptions augmentent la rétention des clients."
            ],
            cta: "Je serais intéressé par..."
        },
        "Une boutique de vêtements": {
            title: "Pourquoi une boutique de vêtements doit-elle être présente en ligne ?",
            content: `
                La présence en ligne est vitale pour les boutiques de vêtements cherchant à rester compétitives et à attirer plus de clients.
                Voici pourquoi :
            `,
            points: [
                "Visibilité accrue : Les consommateurs recherchent des vêtements en ligne avant d'acheter. Être en ligne augmente votre visibilité.",
                "Commerce électronique : Vendre des vêtements en ligne augmente vos ventes et touche un public plus large.",
                "Interaction sociale : Les réseaux sociaux permettent de partager des looks, de recevoir des avis clients et de créer une communauté fidèle.",
                "Promotion des tendances : Partagez les nouvelles tendances et les arrivages récents pour attirer les passionnés de mode.",
                "Fidélisation : Offrez des promotions et des programmes de fidélité en ligne pour encourager les clients à revenir."
            ],
            cta: "Je serais intéressé par..."
        },
        "Un salon de coiffure": {
            title: "Pourquoi un salon de coiffure doit-il être présent en ligne ?",
            content: `
                La présence en ligne est essentielle pour les salons de coiffure souhaitant attirer de nouveaux clients et fidéliser les existants.
                Voici pourquoi :
            `,
            points: [
                "Réservation en ligne : Permettre aux clients de réserver des services en ligne augmente les rendez-vous et réduit les absences.",
                "Visibilité locale : Améliorez votre visibilité auprès des clients potentiels dans votre région.",
                "Portfolio en ligne : Montrez vos réalisations et coiffures pour attirer des clients intéressés par vos compétences.",
                "Engagement client : Utilisez les réseaux sociaux pour interagir avec les clients et partager des conseils de coiffure.",
                "Promotions et offres : Faites la promotion de réductions et d'offres spéciales pour attirer plus de clients."
            ],
            cta: "Je serais intéressé par..."
        },
        "Un cabinet d'avocats": {
            title: "Pourquoi un cabinet d'avocats doit-il être présent en ligne ?",
            content: `
                La présence en ligne est cruciale pour les cabinets d'avocats cherchant à attirer de nouveaux clients et à renforcer leur crédibilité.
                Voici pourquoi :
            `,
            points: [
                "Visibilité : Les clients potentiels recherchent des avocats en ligne. Une présence en ligne augmente votre visibilité.",
                "Crédibilité : Un site web professionnel renforce la crédibilité et montre votre expertise.",
                "Accès à l'information : Fournissez des informations juridiques et des ressources pour aider les clients à comprendre leurs options.",
                "Prise de contact : Facilitez la prise de rendez-vous et la communication avec les clients potentiels.",
                "Marketing ciblé : Utilisez le marketing digital pour cibler des clients spécifiques en fonction de leurs besoins juridiques."
            ],
            cta: "Je serais intéressé par..."
        },
        "Une agence immobilière": {
            title: "Pourquoi une agence immobilière doit-elle être présente en ligne ?",
            content: `
                La présence en ligne est indispensable pour les agences immobilières cherchant à maximiser leurs opportunités de vente et de location.
                Voici pourquoi :
            `,
            points: [
                "Visibilité : Les acheteurs et locataires potentiels recherchent des propriétés en ligne. Une présence en ligne augmente votre visibilité.",
                "Liste de propriétés : Montrez vos propriétés disponibles avec des descriptions détaillées et des photos de haute qualité.",
                "Contact facile : Facilitez la prise de contact et les demandes de renseignements en ligne.",
                "Marketing ciblé : Utilisez des campagnes en ligne pour cibler des acheteurs ou locataires spécifiques.",
                "Gestion des avis : Répondez aux avis en ligne pour améliorer votre réputation et attirer de nouveaux clients."
            ],
            cta: "Je serais intéressé par..."
        },
        "Une clinique de physiothérapie": {
            title: "Pourquoi une clinique de physiothérapie doit-elle être présente en ligne ?",
            content: `
                La présence en ligne est essentielle pour les cliniques de physiothérapie cherchant à attirer de nouveaux patients et à fournir des informations utiles.
                Voici pourquoi :
            `,
            points: [
                "Visibilité locale : Les patients potentiels recherchent des services de physiothérapie en ligne. Une présence en ligne améliore votre visibilité.",
                "Prise de rendez-vous : Facilitez la prise de rendez-vous en ligne pour les patients.",
                "Partage d'informations : Fournissez des ressources et des conseils pour aider les patients à gérer leur condition.",
                "Engagement patient : Utilisez les réseaux sociaux pour partager des témoignages et des succès de patients.",
                "Confiance et crédibilité : Un site web bien conçu renforce la confiance des patients dans vos services."
            ],
            cta: "Je serais intéressé par..."
        },
        "Un magasin de chaussures": {
            title: "Pourquoi un magasin de chaussures doit-il être présent en ligne ?",
            content: `
                La présence en ligne est cruciale pour les magasins de chaussures cherchant à augmenter leurs ventes et à attirer plus de clients.
                Voici pourquoi :
            `,
            points: [
                "Visibilité accrue : Les consommateurs recherchent des chaussures en ligne avant d'acheter. Être présent en ligne augmente votre visibilité.",
                "Commerce électronique : Vendre des chaussures en ligne augmente vos ventes et touche un public plus large.",
                "Interaction sociale : Les réseaux sociaux permettent de partager des avis et des recommandations de clients.",
                "Promotion des tendances : Partagez les nouvelles collections et les tendances actuelles pour attirer les passionnés de mode.",
                "Fidélisation : Offrez des promotions et des programmes de fidélité en ligne pour encourager les clients à revenir."
            ],
            cta: "Je serais intéressé par..."
        },
        "Un salon d'esthétique": {
            title: "Pourquoi un salon d'esthétique doit-il être présent en ligne ?",
            content: `
                La présence en ligne est essentielle pour les salons d'esthétique cherchant à attirer de nouveaux clients et à fidéliser les existants.
                Voici pourquoi :
            `,
            points: [
                "Réservation en ligne : Permettre aux clients de réserver des services en ligne augmente les rendez-vous et réduit les absences.",
                "Visibilité locale : Améliorez votre visibilité auprès des clients potentiels dans votre région.",
                "Portfolio en ligne : Montrez vos réalisations et soins pour attirer des clients intéressés par vos services.",
                "Engagement client : Utilisez les réseaux sociaux pour interagir avec les clients et partager des conseils de beauté.",
                "Promotions et offres : Faites la promotion de réductions et d'offres spéciales pour attirer plus de clients."
            ],
            cta: "Je serais intéressé par..."
        },
        "Une bijouterie": {
            title: "Pourquoi une bijouterie doit-elle être présente en ligne ?",
            content: `
                La présence en ligne est essentielle pour les bijouteries cherchant à attirer plus de clients et à augmenter leurs ventes.
                Voici pourquoi :
            `,
            points: [
                "Visibilité accrue : Les consommateurs recherchent des bijoux en ligne avant d'acheter. Être présent en ligne augmente votre visibilité.",
                "Commerce électronique : Vendre des bijoux en ligne augmente vos ventes et touche un public plus large.",
                "Interaction sociale : Les réseaux sociaux permettent de partager des avis et des recommandations de clients.",
                "Promotion des tendances : Partagez les nouvelles collections et les tendances actuelles pour attirer les passionnés de bijoux.",
                "Fidélisation : Offrez des promotions et des programmes de fidélité en ligne pour encourager les clients à revenir."
            ],
            cta: "Je serais intéressé par..."
        },
        "Un studio de yoga": {
            title: "Pourquoi un studio de yoga doit-il être présent en ligne ?",
            content: `
                La présence en ligne est cruciale pour les studios de yoga cherchant à attirer plus de clients et à offrir des ressources utiles.
                Voici pourquoi :
            `,
            points: [
                "Visibilité locale : Les clients potentiels recherchent des cours de yoga en ligne avant de s'inscrire. Être présent en ligne augmente votre visibilité.",
                "Réservation en ligne : Permettre aux clients de réserver des cours en ligne augmente les inscriptions.",
                "Partage de contenu : Publiez des vidéos et des articles sur le yoga pour attirer et fidéliser les clients.",
                "Engagement client : Utilisez les réseaux sociaux pour interagir avec les clients et partager des conseils de bien-être.",
                "Promotion des événements : Faites la promotion de workshops et d'événements spéciaux pour attirer plus de participants."
            ],
            cta: "Je serais intéressé par..."
        },
        "Un traiteur": {
            title: "Pourquoi un traiteur doit-il être présent en ligne ?",
            content: `
                La présence en ligne est essentielle pour les traiteurs cherchant à attirer plus de clients et à promouvoir leurs services.
                Voici pourquoi :
            `,
            points: [
                "Visibilité accrue : Les clients potentiels recherchent des services de traiteur en ligne avant de les engager. Être présent en ligne augmente votre visibilité.",
                "Portfolio en ligne : Montrez vos réalisations et événements pour attirer des clients intéressés par vos services.",
                "Réservation en ligne : Facilitez la réservation de vos services en ligne pour augmenter les demandes.",
                "Engagement client : Utilisez les réseaux sociaux pour interagir avec les clients et partager des témoignages de réussite.",
                "Promotion des offres : Faites la promotion de menus spéciaux et d'offres pour attirer plus de clients."
            ],
            cta: "Je serais intéressé par..."
        },
        "Une boulangerie": {
            title: "Pourquoi une boulangerie doit-elle être présente en ligne ?",
            content: `
                La présence en ligne est cruciale pour les boulangeries cherchant à attirer plus de clients et à promouvoir leurs produits.
                Voici pourquoi :
            `,
            points: [
                "Visibilité locale : Les clients potentiels recherchent des boulangeries en ligne avant de les visiter. Être présent en ligne augmente votre visibilité.",
                "Commande en ligne : Permettre aux clients de commander en ligne augmente les ventes et facilite les achats.",
                "Partage de contenu : Publiez des articles et des vidéos sur la boulangerie pour attirer et fidéliser les clients.",
                "Engagement client : Utilisez les réseaux sociaux pour interagir avec les clients et partager des conseils de pâtisserie.",
                "Promotion des événements : Faites la promotion de nouveaux produits et d'événements spéciaux pour attirer plus de clients."
            ],
            cta: "Je serais intéressé par..."
        },
        "Un studio de fitness et gymnase": {
            title: "Pourquoi un studio de fitness et gymnase doit-il être présent en ligne ?",
            content: `
                La présence en ligne est essentielle pour les studios de fitness et gymnases cherchant à attirer plus de clients et à offrir des ressources utiles.
                Voici pourquoi :
            `,
            points: [
                "Visibilité locale : Les clients potentiels recherchent des cours de fitness en ligne avant de s'inscrire. Être présent en ligne augmente votre visibilité.",
                "Réservation en ligne : Permettre aux clients de réserver des cours en ligne augmente les inscriptions.",
                "Partage de contenu : Publiez des vidéos et des articles sur le fitness pour attirer et fidéliser les clients.",
                "Engagement client : Utilisez les réseaux sociaux pour interagir avec les clients et partager des conseils de bien-être.",
                "Promotion des événements : Faites la promotion de workshops et d'événements spéciaux pour attirer plus de participants."
            ],
            cta: "Je serais intéressé par..."
        },
        "Un service de nettoyage": {
            title: "Pourquoi un service de nettoyage doit-il être présent en ligne ?",
            content: `
                La présence en ligne est cruciale pour les services de nettoyage cherchant à attirer plus de clients et à promouvoir leurs services.
                Voici pourquoi :
            `,
            points: [
                "Visibilité accrue : Les clients potentiels recherchent des services de nettoyage en ligne avant de les engager. Être présent en ligne augmente votre visibilité.",
                "Portfolio en ligne : Montrez vos réalisations et événements pour attirer des clients intéressés par vos services.",
                "Réservation en ligne : Facilitez la réservation de vos services en ligne pour augmenter les demandes.",
                "Engagement client : Utilisez les réseaux sociaux pour interagir avec les clients et partager des témoignages de réussite.",
                "Promotion des offres : Faites la promotion de menus spéciaux et d'offres pour attirer plus de clients."
            ],
            cta: "Je serais intéressé par..."
        },
        "Un consultant": {
            title: "Pourquoi un consultant doit-il être présent en ligne ?",
            content: `
                La présence en ligne est cruciale pour les consultants cherchant à attirer plus de clients et à promouvoir leurs services.
                Voici pourquoi :
            `,
            points: [
                "Visibilité accrue : Les clients potentiels recherchent des services de consultation en ligne avant de les engager. Être présent en ligne augmente votre visibilité.",
                "Portfolio en ligne : Montrez vos réalisations et événements pour attirer des clients intéressés par vos services.",
                "Réservation en ligne : Facilitez la réservation de vos services en ligne pour augmenter les demandes.",
                "Engagement client : Utilisez les réseaux sociaux pour interagir avec les clients et partager des témoignages de réussite.",
                "Promotion des offres : Faites la promotion de menus spéciaux et d'offres pour attirer plus de clients."
            ],
            cta: "Je serais intéressé par..."
        },
        "Un spa": {
            title: "Pourquoi un spa doit-il être présent en ligne ?",
            content: `
                La présence en ligne est cruciale pour les spas cherchant à attirer plus de clients et à promouvoir leurs services.
                Voici pourquoi :
            `,
            points: [
                "Visibilité accrue : Les clients potentiels recherchent des services de spa en ligne avant de les engager. Être présent en ligne augmente votre visibilité.",
                "Portfolio en ligne : Montrez vos réalisations et événements pour attirer des clients intéressés par vos services.",
                "Réservation en ligne : Facilitez la réservation de vos services en ligne pour augmenter les demandes.",
                "Engagement client : Utilisez les réseaux sociaux pour interagir avec les clients et partager des témoignages de réussite.",
                "Promotion des offres : Faites la promotion de menus spéciaux et d'offres pour attirer plus de clients."
            ],
            cta: "Je serais intéressé par..."
        },
        "Un comptable": {
            title: "Pourquoi un comptable doit-il être présent en ligne ?",
            content: `
                La présence en ligne est cruciale pour les comptables cherchant à attirer plus de clients et à promouvoir leurs services.
                Voici pourquoi :
            `,
            points: [
                "Visibilité accrue : Les clients potentiels recherchent des services de comptabilité en ligne avant de les engager. Être présent en ligne augmente votre visibilité.",
                "Portfolio en ligne : Montrez vos réalisations et événements pour attirer des clients intéressés par vos services.",
                "Réservation en ligne : Facilitez la réservation de vos services en ligne pour augmenter les demandes.",
                "Engagement client : Utilisez les réseaux sociaux pour interagir avec les clients et partager des témoignages de réussite.",
                "Promotion des offres : Faites la promotion de menus spéciaux et d'offres pour attirer plus de clients."
            ],
            cta: "Je serais intéressé par..."
        },
        "Une épicerie fine": {
            title: "Pourquoi une épicerie fine doit-elle être présente en ligne ?",
            content: `
                La présence en ligne est cruciale pour les épiceries fines cherchant à attirer plus de clients et à promouvoir leurs produits.
                Voici pourquoi :
            `,
            points: [
                "Visibilité accrue : Les clients potentiels recherchent des produits d'épicerie en ligne avant de les acheter. Être présent en ligne augmente votre visibilité.",
                "Commerce électronique : Vendre des produits en ligne augmente vos ventes et touche un public plus large.",
                "Interaction sociale : Les réseaux sociaux permettent de partager des avis et des recommandations de clients.",
                "Promotion des tendances : Partagez les nouvelles collections et les tendances actuelles pour attirer les passionnés de mode.",
                "Fidélisation : Offrez des promotions et des programmes de fidélité en ligne pour encourager les clients à revenir."
            ],
            cta: "Je serais intéressé par..."
        },
        "Un service pour animaux": {
            title: "Pourquoi un service pour animaux doit-il être présent en ligne ?",
            content: `
                La présence en ligne est cruciale pour les services pour animaux cherchant à attirer plus de clients et à promouvoir leurs services.
                Voici pourquoi :
            `,
            points: [
                "Visibilité accrue : Les clients potentiels recherchent des services pour animaux en ligne avant de les engager. Être présent en ligne augmente votre visibilité.",
                "Portfolio en ligne : Montrez vos réalisations et événements pour attirer des clients intéressés par vos services.",
                "Réservation en ligne : Facilitez la réservation de vos services en ligne pour augmenter les demandes.",
                "Engagement client : Utilisez les réseaux sociaux pour interagir avec les clients et partager des témoignages de réussite.",
                "Promotion des offres : Faites la promotion de menus spéciaux et d'offres pour attirer plus de clients."
            ],
            cta: "Je serais intéressé par..."
        },
        "Un salon de manucure": {
            title: "Pourquoi un salon de manucure doit-il être présent en ligne ?",
            content: `
                La présence en ligne est cruciale pour les salons de manucure cherchant à attirer plus de clients et à promouvoir leurs services.
                Voici pourquoi :
            `,
            points: [
                "Visibilité accrue : Les clients potentiels recherchent des services de manucure en ligne avant de les engager. Être présent en ligne augmente votre visibilité.",
                "Portfolio en ligne : Montrez vos réalisations et événements pour attirer des clients intéressés par vos services.",
                "Réservation en ligne : Facilitez la réservation de vos services en ligne pour augmenter les demandes.",
                "Engagement client : Utilisez les réseaux sociaux pour interagir avec les clients et partager des témoignages de réussite.",
                "Promotion des offres : Faites la promotion de menus spéciaux et d'offres pour attirer plus de clients."
            ],
            cta: "Je serais intéressé par..."
        },
        "Une pâtisserie": {
            title: "Pourquoi une pâtisserie doit-elle être présente en ligne ?",
            content: `
                La présence en ligne est cruciale pour les pâtisseries cherchant à attirer plus de clients et à promouvoir leurs produits.
                Voici pourquoi :
            `,
            points: [
                "Visibilité accrue : Les clients potentiels recherchent des produits de pâtisserie en ligne avant de les acheter. Être présent en ligne augmente votre visibilité.",
                "Commerce électronique : Vendre des produits en ligne augmente vos ventes et touche un public plus large.",
                "Interaction sociale : Les réseaux sociaux permettent de partager des avis et des recommandations de clients.",
                "Promotion des tendances : Partagez les nouvelles collections et les tendances actuelles pour attirer les passionnés de mode.",
                "Fidélisation : Offrez des promotions et des programmes de fidélité en ligne pour encourager les clients à revenir."
            ],
            cta: "Je serais intéressé par..."
        },
        "Un photographe": {
            title: "Pourquoi un photographe doit-il être présent en ligne ?",
            content: `
                La présence en ligne est cruciale pour les photographes cherchant à attirer plus de clients et à promouvoir leurs services.
                Voici pourquoi :
            `,
            points: [
                "Visibilité accrue : Les clients potentiels recherchent des services de photographie en ligne avant de les engager. Être présent en ligne augmente votre visibilité.",
                "Portfolio en ligne : Montrez vos réalisations et événements pour attirer des clients intéressés par vos services.",
                "Réservation en ligne : Facilitez la réservation de vos services en ligne pour augmenter les demandes.",
                "Engagement client : Utilisez les réseaux sociaux pour interagir avec les clients et partager des témoignages de réussite.",
                "Promotion des offres : Faites la promotion de menus spéciaux et d'offres pour attirer plus de clients."
            ],
            cta: "Je serais intéressé par..."
        },
        "Un magasin de lunettes": {
            title: "Pourquoi un magasin de lunettes doit-il être présent en ligne ?",
            content: `
                La présence en ligne est cruciale pour les magasins de lunettes cherchant à attirer plus de clients et à promouvoir leurs produits.
                Voici pourquoi :
            `,
            points: [
                "Visibilité accrue : Les clients potentiels recherchent des lunettes en ligne avant de les acheter. Être présent en ligne augmente votre visibilité.",
                "Commerce électronique : Vendre des produits en ligne augmente vos ventes et touche un public plus large.",
                "Interaction sociale : Les réseaux sociaux permettent de partager des avis et des recommandations de clients.",
                "Promotion des tendances : Partagez les nouvelles collections et les tendances actuelles pour attirer les passionnés de mode.",
                "Fidélisation : Offrez des promotions et des programmes de fidélité en ligne pour encourager les clients à revenir."
            ],
            cta: "Je serais intéressé par..."
        },
        "Un magasin de vélos": {
            title: "Pourquoi un magasin de vélos doit-il être présent en ligne ?",
            content: `
                La présence en ligne est cruciale pour les magasins de vélos cherchant à attirer plus de clients et à promouvoir leurs produits.
                Voici pourquoi :
            `,
            points: [
                "Visibilité accrue : Les clients potentiels recherchent des vélos en ligne avant de les acheter. Être présent en ligne augmente votre visibilité.",
                "Commerce électronique : Vendre des produits en ligne augmente vos ventes et touche un public plus large.",
                "Interaction sociale : Les réseaux sociaux permettent de partager des avis et des recommandations de clients.",
                "Promotion des tendances : Partagez les nouvelles collections et les tendances actuelles pour attirer les passionnés de mode.",
                "Fidélisation : Offrez des promotions et des programmes de fidélité en ligne pour encourager les clients à revenir."
            ],
            cta: "Je serais intéressé par..."
        },
        "Un marché fermier": {
            title: "Pourquoi un marché fermier doit-il être présent en ligne ?",
            content: `
                La présence en ligne est cruciale pour les marchés fermiers cherchant à attirer plus de clients et à promouvoir leurs produits.
                Voici pourquoi :
            `,
            points: [
                "Visibilité accrue : Les clients potentiels recherchent des produits de marché en ligne avant de les acheter. Être présent en ligne augmente votre visibilité.",
                "Commerce électronique : Vendre des produits en ligne augmente vos ventes et touche un public plus large.",
                "Interaction sociale : Les réseaux sociaux permettent de partager des avis et des recommandations de clients.",
                "Promotion des tendances : Partagez les nouvelles collections et les tendances actuelles pour attirer les passionnés de mode.",
                "Fidélisation : Offrez des promotions et des programmes de fidélité en ligne pour encourager les clients à revenir."
            ],
            cta: "Je serais intéressé par..."
        },
        "Une microbrasserie": {
            title: "Pourquoi une microbrasserie doit-elle être présente en ligne ?",
            content: `
                La présence en ligne est cruciale pour les microbrasseries cherchant à attirer plus de clients et à promouvoir leurs produits.
                Voici pourquoi :
            `,
            points: [
                "Visibilité accrue : Les clients potentiels recherchent des produits de microbrasserie en ligne avant de les acheter. Être présent en ligne augmente votre visibilité.",
                "Commerce électronique : Vendre des produits en ligne augmente vos ventes et touche un public plus large.",
                "Interaction sociale : Les réseaux sociaux permettent de partager des avis et des recommandations de clients.",
                "Promotion des tendances : Partagez les nouvelles collections et les tendances actuelles pour attirer les passionnés de mode.",
                "Fidélisation : Offrez des promotions et des programmes de fidélité en ligne pour encourager les clients à revenir."
            ],
            cta: "Je serais intéressé par..."
        },
        "Une boutique de décoration intérieure": {
            title: "Pourquoi une boutique de décoration intérieure doit-elle être présente en ligne ?",
            content: `
                La présence en ligne est cruciale pour les boutiques de décoration intérieure cherchant à attirer plus de clients et à promouvoir leurs produits.
                Voici pourquoi :
            `,
            points: [
                "Visibilité accrue : Les clients potentiels recherchent des produits de décoration en ligne avant de les acheter. Être présent en ligne augmente votre visibilité.",
                "Commerce électronique : Vendre des produits en ligne augmente vos ventes et touche un public plus large.",
                "Interaction sociale : Les réseaux sociaux permettent de partager des avis et des recommandations de clients.",
                "Promotion des tendances : Partagez les nouvelles collections et les tendances actuelles pour attirer les passionnés de mode.",
                "Fidélisation : Offrez des promotions et des programmes de fidélité en ligne pour encourager les clients à revenir."
            ],
            cta: "Je serais intéressé par..."
        },
        "Un psychologue": {
            title: "Pourquoi un psychologue doit-il être présent en ligne ?",
            content: `
                La présence en ligne est cruciale pour les psychologues cherchant à attirer plus de clients et à promouvoir leurs services.
                Voici pourquoi :
            `,
            points: [
                "Visibilité accrue : Les clients potentiels recherchent des services de psychologie en ligne avant de les engager. Être présent en ligne augmente votre visibilité.",
                "Portfolio en ligne : Montrez vos réalisations et événements pour attirer des clients intéressés par vos services.",
                "Réservation en ligne : Facilitez la réservation de vos services en ligne pour augmenter les demandes.",
                "Engagement client : Utilisez les réseaux sociaux pour interagir avec les clients et partager des témoignages de réussite.",
                "Promotion des offres : Faites la promotion de menus spéciaux et d'offres pour attirer plus de clients."
            ],
            cta: "Je serais intéressé par..."
        },
        "Un centre de copie et impression": {
            title: "Pourquoi un centre de copie et impression doit-il être présent en ligne ?",
            content: `
                La présence en ligne est cruciale pour les centres de copie et impression cherchant à attirer plus de clients et à promouvoir leurs services.
                Voici pourquoi :
            `,
            points: [
                "Visibilité accrue : Les clients potentiels recherchent des services de copie et impression en ligne avant de les engager. Être présent en ligne augmente votre visibilité.",
                "Portfolio en ligne : Montrez vos réalisations et événements pour attirer des clients intéressés par vos services.",
                "Réservation en ligne : Facilitez la réservation de vos services en ligne pour augmenter les demandes.",
                "Engagement client : Utilisez les réseaux sociaux pour interagir avec les clients et partager des témoignages de réussite.",
                "Promotion des offres : Faites la promotion de menus spéciaux et d'offres pour attirer plus de clients."
            ],
            cta: "Je serais intéressé par..."
        },
        "Une boutique de matériel électronique": {
            title: "Pourquoi une boutique de matériel électronique doit-elle être présente en ligne ?",
            content: `
                La présence en ligne est cruciale pour les boutiques de matériel électronique cherchant à attirer plus de clients et à promouvoir leurs produits.
                Voici pourquoi :
            `,
            points: [
                "Visibilité accrue : Les clients potentiels recherchent des produits électroniques en ligne avant de les acheter. Être présent en ligne augmente votre visibilité.",
                "Commerce électronique : Vendre des produits en ligne augmente vos ventes et touche un public plus large.",
                "Interaction sociale : Les réseaux sociaux permettent de partager des avis et des recommandations de clients.",
                "Promotion des tendances : Partagez les nouvelles collections et les tendances actuelles pour attirer les passionnés de mode.",
                "Fidélisation : Offrez des promotions et des programmes de fidélité en ligne pour encourager les clients à revenir."
            ],
            cta: "Je serais intéressé par..."
        },
        "Un service de taxi": {
            title: "Pourquoi un service de taxi doit-il être présent en ligne ?",
            content: `
                La présence en ligne est cruciale pour les services de taxi cherchant à attirer plus de clients et à promouvoir leurs services.
                Voici pourquoi :
            `,
            points: [
                "Visibilité accrue : Les clients potentiels recherchent des services de taxi en ligne avant de les engager. Être présent en ligne augmente votre visibilité.",
                "Portfolio en ligne : Montrez vos réalisations et événements pour attirer des clients intéressés par vos services.",
                "Réservation en ligne : Facilitez la réservation de vos services en ligne pour augmenter les demandes.",
                "Engagement client : Utilisez les réseaux sociaux pour interagir avec les clients et partager des témoignages de réussite.",
                "Promotion des offres : Faites la promotion de menus spéciaux et d'offres pour attirer plus de clients."
            ],
            cta: "Je serais intéressé par..."
        }
    };

    const { title, content, points, cta } = profileTexts[profile] || {}; 
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('/service-form');
    };

    return (
        <div className="problem-solution-fit">
            <h2>{title}</h2>
            <p>{content}</p>
            <ul>
                {points && points.map((point, index) => (
                    <li key={index}>{point}</li>
                ))}
            </ul>
            <button onClick={handleButtonClick} className="cta-button">{cta}</button>
        </div>
    );
}

export default ProblemSolutionFit;
