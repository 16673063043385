// src/components/About/About.js
import React from 'react';
import './About.css';

const About = () => {
  return (
    <div className="about-section">
      <h2>À propos de nous</h2>
      <div className="about-content">
        <h3>Historique de l'entreprise</h3>
        <p>En 2024, suite à une frustration de voir trop de commerces de proximité uniquement référencées sur société.com ce qui peine à leur image, notre entreprise a été fondée. Ne dit on pas que c'est la première impression qui compte ? Sachant que Google est un canal incontournable, tomber sur societe.com (que nous saluons au passage pour la qualité des informations qu'il fournies et l'immensite de sa base de données) dans les premiers résultats n'est pas optimal. Nous avons donc décidé de changer la donne et de vous emmener au delà de société.com. Site internet, réseaux sociaux, contenu en abondance, funnel de conversion, de quoi vous batir une identité digitale forte et attirer de nouveaux clients.</p>
        <p>Notre mission est donc d'aider les commerces de proximité de France à développer leur notoriété et leur chiffre d'affaires au travers d'une présence forte sur le digital en cette période économique difficile. Notre vision est de populariser la présence des commerces de proximité sur le digital et de faire baisser drastiquement le nombre d'entreprises uniquement sur societe.com.</p>

        <h3>Valeurs de l'entreprise</h3>
        <ul>
          <li><strong>Générosité:</strong> Nous nous battons pour développer le CA et la notoriété de l'entreprise au-delà de la présence digitale au travers de conseils stratégiques et marketing de développement. Nous avons à cœur le succès de nos clients. Nous offrons l'hébergement et le nombre de pages est illimité, quel que soit le package choisi.</li>
          <li><strong>Souplesse:</strong> Nous avons une approche transversale pour développer le CA et la notoriété de l'entreprise. Nous pouvons conseiller sur le funnel de conversion idéal et mettre en œuvre les idées les plus folles de nos clients en créant des animations sur mesure, par exemple. Le livrable fourni est un code source que l'on peut mettre en ligne chez n'importe quel hébergeur afin de ne pas dépendre d'un CMS et d'abonnements exorbitants.</li>
          <li><strong>Excellence:</strong> L'expérience utilisateur doit être optimale.</li>
          <li><strong>Disponibilité:</strong> Nous suivons de près la satisfaction client.</li>
        </ul>

        <h3>Notre équipe</h3>
        <p>Emmanuel Baudrier est diplômé en marketing (bac +5) et spécialisé dans l'IA, ayant participé à la rédaction de "L'IA générative pour les nuls". Il possède une double expertise en marketing et programmation informatique.</p>

        <h3>Nos produits et services</h3>
        <p>Nous offrons des services de création de sites web, SEO et gestion des réseaux sociaux.</p>

        <h3>Nos clients et notre marché</h3>
        <p>Nous nous adressons principalement aux commerces de proximité. Nous assurons de répondre à leurs besoins et attentes par une écoute attentive et un cahier des charges sur mesure.</p>

        <h3>Réalisations</h3>
        <p>Nous avons notamment travaillé avec Pub Brasserie du restaurant le village.</p>

        <h3>Contact</h3>
        <p>Vous pouvez nous contacter via le <a href="/contact-form">formulaire de contact</a>.</p>
        <p>Plus d'informations sur <a href="https://pubbrasserielevillage.fr" target="_blank" rel="noopener noreferrer">pubbrasserielevillage.fr</a>.</p>
      </div>
    </div>
  );
};

export default About;
