import React, { useState } from 'react';
import './HomePage.css';
import ProfileForm from '../2.ProfileForm/ProfileForm';

function HomePage() {
  const [page, setPage] = useState(1);

  return (
    <div className="homePage">
      {page === 1 && (
        <>
          <img src="/1.png" alt="Bienvenue" className="background-image" />
          <button className="cta" onClick={() => setPage(2)}>J'embarque dès maintenant !</button>
        </>
      )}
      {page === 2 && (
        <>
          <img src="/2.png" alt="Page 2" className="background-image2" />
          <button className="cta2" onClick={() => setPage(3)}>Voir plus</button>
        </>
      )}
      {page === 3 && (
        <>
          <img src="/3.png" alt="Page 3" className="background-image3" />
          <button className="cta3" onClick={() => setPage(4)}>On continue !</button>
        </>
      )}
      {page === 4 && (
        <ProfileForm />
      )}
      {/* Continuer ainsi pour d'autres pages si nécessaire */}
    </div>
  );
}

export default HomePage;
