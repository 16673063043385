import React, { useState } from 'react';
import './ProfileForm.css';
import ProblemSolutionFit from '../3.ProblemSolutionFit/ProblemSolutionFit';

function ProfileForm() {
    const [profile, setProfile] = useState('');
    const [emoji, setEmoji] = useState('👤');
    const [buttonText, setButtonText] = useState('Pourquoi être présent en ligne ?');
    const [showButton, setShowButton] = useState(false);
    const [showProblemSolution, setShowProblemSolution] = useState(false);

    const profiles = {
        "Un restaurant": { emoji: "👨‍🍳", gender: "il" },
        "Un café": { emoji: "☕", gender: "il" },
        "Un hôtel": { emoji: "🏨", gender: "il" },
        "Une pharmacie": { emoji: "💊", gender: "elle" },
        "Une boutique de vêtements": { emoji: "👗", gender: "elle" },
        "Un salon de coiffure": { emoji: "💇", gender: "il" },
        "Un cabinet d'avocats": { emoji: "⚖️", gender: "il" },
        "Une agence immobilière": { emoji: "🏠", gender: "elle" },
        "Une clinique de physiothérapie": { emoji: "🩺", gender: "elle" },
        "Un magasin de chaussures": { emoji: "👠", gender: "il" },
        "Un salon d'esthétique": { emoji: "💅", gender: "il" },
        "Une bijouterie": { emoji: "💍", gender: "elle" },
        "Un studio de yoga": { emoji: "🧘", gender: "il" },
        "Un traiteur": { emoji: "🍽", gender: "il" },
        "Une boulangerie": { emoji: "🥖", gender: "elle" },
        "Un studio de fitness et gymnase": { emoji: "🏋️‍♂️", gender: "il" },
        "Un service de nettoyage": { emoji: "🧹", gender: "il" },
        "Un consultant": { emoji: "💼", gender: "il" },
        "Un spa": { emoji: "🧖‍♀️", gender: "elle" },
        "Un comptable": { emoji: "🧾", gender: "il" },
        "Une épicerie fine": { emoji: "🧀", gender: "elle" },
        "Un service pour animaux": { emoji: "🐶", gender: "il" },
        "Un salon de manucure": { emoji: "💅", gender: "il" },
        "Une pâtisserie": { emoji: "🍰", gender: "elle" },
        "Un photographe": { emoji: "📷", gender: "il" },
        "Un magasin de lunettes": { emoji: "👓", gender: "il" },
        "Un magasin de vélos": { emoji: "🚲", gender: "il" },
        "Un marché fermier": { emoji: "🌾", gender: "il" },
        "Une microbrasserie": { emoji: "🍻", gender: "elle" },
        "Une boutique de décoration intérieure": { emoji: "🛋", gender: "elle" },
        "Un psychologue": { emoji: "🧠", gender: "il" },
        "Un centre de copie et impression": { emoji: "🖨", gender: "il" },
        "Une boutique de matériel électronique": { emoji: "🔌", gender: "elle" },
        "Un service de taxi": { emoji: "🚕", gender: "il" }
    };

    const handleSelectChange = (event) => {
        const selectedProfile = event.target.value;
        const profileInfo = profiles[selectedProfile];
        setProfile(selectedProfile);
        setEmoji(profileInfo?.emoji || '👤');
        const profileWithLowercase = selectedProfile.charAt(0).toLowerCase() + selectedProfile.slice(1);
        const conjugation = profileInfo?.gender === "elle" ? "doit-elle" : "doit-il";
        setButtonText(`Pourquoi ${profileWithLowercase} ${conjugation} être présent en ligne ?`);
        setShowButton(true);
        setShowProblemSolution(false);
    };

    const handleButtonClick = () => {
        setShowProblemSolution(true);
    };

    return (
        <div className="profile-form">
            <h1>Je suis...</h1>
            <h2 className="emoji-large">{emoji}</h2>
            <select value={profile} onChange={handleSelectChange}>
                <option value="" disabled hidden>Sélectionner</option>
                {Object.keys(profiles).map((item, index) => (
                    <option key={index} value={item}>{item}</option>
                ))}
            </select>
            {showButton && !showProblemSolution && (
                <button className="custom-button" onClick={handleButtonClick}>{buttonText}</button>
            )}
            {showProblemSolution && <ProblemSolutionFit profile={profile} />}
        </div>
    );
}

export default ProfileForm;
