import React from 'react';
import { Link } from 'react-router-dom';
import './ServiceForm.css';

const ServiceForm = () => {
  const packages = [
    {
      name: 'Pack Site Web Essentiel',
      price: '1000 - 1200€',
      features: [
        'Site web complet (nombre de pages illimité)',
        'Code source ultra flexible livré (pas d\'abonnement CMS Wordpress ou autre)',
        'Design personnalisé et sur mesure (conception graphique, animation, bruitage bande son original...)',
        'Hébergement offert sur Hostinger',
        'Nom de domaine offert la première année',
        'Consulting marketing offert (funnel de conversions, opportunité de développement commercial, révision du positionnement, framework de croissance...)',
        'Garantie satisfait ou remboursé sous 30 jours'
      ]
    },
    {
      name: 'Pack Site Web + SEO Avancé',
      price: '1500 - 1700€',
      features: [
        'Site web complet (nombre de pages illimité)',
        'Code source ultra flexible livré (pas d\'abonnement CMS Wordpress ou autre)',
        'Design personnalisé et sur mesure (conception graphique, animation, bruitage bande son original...)',
        'Optimisation SEO avancée',
        'Hébergement offert sur Hostinger',
        'Nom de domaine offert la première année',
        'Consulting marketing offert (funnel de conversions, opportunité de développement commercial, révision du positionnement, framework de croissance...)',
        'Garantie satisfait ou remboursé sous 30 jours'
      ]
    },
    {
      name: 'Pack Complet : Site Web + SEO + Réseaux Sociaux',
      price: '1800 - 2000€',
      features: [
        'Site web complet (nombre de pages illimité)',
        'Code source ultra flexible livré (pas d\'abonnement CMS Wordpress ou autre)',
        'Design personnalisé et sur mesure (conception graphique, animation, bruitage bande son original...)',
        'SEO avancé',
        'Gestion réseaux sociaux (10 posts/mois)',
        'Hébergement offert sur Hostinger',
        'Nom de domaine offert la première année',
        'Consulting marketing offert (funnel de conversions, opportunité de développement commercial, révision du positionnement, framework de croissance...)',
        'Garantie satisfait ou remboursé sous 30 jours'
      ]
    }
  ];

  return (
    <div className="service-form">
      <h2 className="service-form-title">Choisissez votre package :</h2>
      <div className="package-container">
        {packages.map((pkg, index) => (
          <div key={index} className="package-card">
            <h3 className="package-title">{pkg.name}</h3> {/* Ajout de la classe package-title */}
            <p className="package-price">{pkg.price}</p>
            <ul className="feature-list">
              {pkg.features.map((feature, idx) => (
                <li key={idx} className="feature-item">{feature}</li>
              ))}
            </ul>
            <Link to="/contact-form">
              <button className="select-button">Je souhaite être rappelé</button>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServiceForm;
